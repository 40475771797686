/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationLifeStatusFilter,
  AsyncState,
  SearchCriteria,
  useGroupsAndAgents,
} from '@ozark/common';
import {CancelOperationMessage} from '@ozark/common/api/Constants';
import {
  ActiveFilter,
  ApplicationFilters,
  ApplicationListData,
  Card,
  forceActiveFilter,
  InfiniteEntities,
  InputSearch,
  PanelItem,
  Title,
} from '@ozark/common/components';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';
import {CancelTokenSource} from 'axios';
import {orderBy as orderByLodash} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {ApplicationsExportButton} from '../ApplicationsExportButton';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

const DEFAULT_VALUE = '0';

const defaultFilters = {
  showAll: true,
  lifeStatus: ApplicationLifeStatusFilter.activeOnly,
};

const defaultPaging: SearchCriteria = {
  order: 'desc',
  orderBy: 'createdAt',
  limit: 50, // page size
  offset: 1, // page
};

const addShowAllFilter = (target: ActiveFilter[], showAll: boolean, uid: string): void => {
  if (showAll) return;
  target.push(forceActiveFilter(ApplicationFilters, 'uid', '__eq', uid));
};

const addGroupIdFilter = (target: ActiveFilter[], groupId: string | null): void => {
  if (groupId == null || groupId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(ApplicationFilters, 'groupId', '__eq', groupId));
};

const addAgentFilter = (target: ActiveFilter[], agentId: string | null): void => {
  if (agentId == null || agentId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(ApplicationFilters, 'agentId', '__eq', agentId));
};

const addLifeStatusFilter = (target: ActiveFilter[], status: ApplicationLifeStatusFilter): void => {
  let filter: ActiveFilter | null = null;
  switch (status) {
    case ApplicationLifeStatusFilter.activeOnly:
      filter = forceActiveFilter(ApplicationFilters, 'deleted', '__eq', 'false');
      break;
    case ApplicationLifeStatusFilter.deletedOnly:
      filter = forceActiveFilter(ApplicationFilters, 'deleted', '__eq', 'true');
      break;
  }
  if (filter != null) {
    target.push(filter);
  }
};

const buildFilters = (input: {
  uid: string;
  showAll: boolean;
  lifeStatus: ApplicationLifeStatusFilter;
  groupId: string | null;
  agentId: string | null;
}): ActiveFilter[] => {
  const result: ActiveFilter[] = [];

  addShowAllFilter(result, input.showAll, input.uid);
  addLifeStatusFilter(result, input.lifeStatus);
  addGroupIdFilter(result, input.groupId);
  addAgentFilter(result, input.agentId);

  return result;
};

const ConditionallyApproved = () => {
  const classes = useStyles();
  const history = useHistory();
  const {authProfile, isUserAdmin, apiClient, profiles, groupsMap} = useStore();
  const isAdmin = isUserAdmin();
  const initialized = useRef(false);

  const {groups, onGroupChange, selectedGroup, filteredAgents, onAgentChange, selectedAgent} =
    useGroupsAndAgents();
  const [showAll, setShowAll] = useState(defaultFilters.showAll);
  const [lifeStatus, setLifeStatus] = useState(defaultFilters.lifeStatus);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCriteria, setSearchCriteria] = useState(defaultPaging);

  const [isLoadingFirstPage, setIsLoadingFirstPage] = useState(false);
  const [_, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
  const [applications, setApplications] = useState<AsyncState<ApplicationListData>>({
    promised: true,
  });

  useEffect(() => {
    // This is to prevent the initial load from firing twice in the strict mode.
    if (!initialized.current) {
      initialized.current = true;
      loadData(searchCriteria);
    }
  }, []);

  useEffect(() => {
    const newPaging = {...searchCriteria, offset: 1};
    setSearchCriteria(newPaging);
    loadData(newPaging);
  }, [showAll, lifeStatus, selectedGroup, selectedAgent, searchQuery]);

  const loadData = (paging: SearchCriteria, onLoaded?: () => void) => {
    if (paging.offset === 1) {
      setIsLoadingFirstPage(true);
    }

    const cancelSource = apiClient?.applications.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    const filters = buildFilters({
      uid: authProfile.data!.id,
      showAll,
      lifeStatus,
      groupId: selectedGroup,
      agentId: selectedAgent,
    });
    apiClient.applications
      .getConditionallyApprovedApplications(paging, searchQuery, filters, cancelSource?.token)
      .then(result => {
        if (result == null) {
          setApplications({promised: false, data: {items: [], hasNextPage: false}});
          return;
        }

        const listData: ApplicationListData = {
          items:
            result.offset === 0
              ? result.data
              : [...(applications.data?.items ?? []), ...result.data],
          hasNextPage: result.data.length === result.limit || false,
          totalCount: result.totalCount,
        };
        setApplications({promised: false, data: listData});
        setIsLoadingFirstPage(false);
      })
      .catch((err: any) => {
        if (err?.message === CancelOperationMessage) {
          return;
        }
        console.error(err);
        setApplications({promised: false, error: err || {}});
        setIsLoadingFirstPage(false);
      })
      .finally(() => {
        onLoaded?.();
      });
  };

  const fetchAll = async () => {
    let allApplications: ApplicationResponse[] = [];
    const cancelSource = apiClient?.applications.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    const filters = buildFilters({
      uid: authProfile.data!.id,
      showAll,
      lifeStatus,
      groupId: selectedGroup,
      agentId: selectedAgent,
    });

    try {
      const result = await apiClient.applications.getConditionallyApprovedApplications(
        {...searchCriteria, limit: 0},
        searchQuery,
        filters,
        cancelSource?.token
      );

      if (result == null) {
        return [];
      }

      allApplications = result.data;
    } catch (err: any) {
      console.error(err);
    }

    return allApplications;
  };

  const toggleOrder = () => {
    const newValue = {...searchCriteria, order: searchCriteria.order === 'asc' ? 'desc' : 'asc'};
    setSearchCriteria(newValue);
    loadData(newValue);
  };

  const toggleShowAll = () => setShowAll(!showAll);

  const handleLifeStatusChange = (event: any) => setLifeStatus(event.target.value);

  const handleGroupChange = (event: any) => onGroupChange(event.target.value);

  const handleAgentChange = (event: any) => onAgentChange(event.target.value);

  const loadNextPage = (onLoaded?: () => void): void => {
    const newValue = {...searchCriteria, offset: searchCriteria.offset + 1};
    setSearchCriteria(newValue);
    loadData(newValue, onLoaded);
  };

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{display: 'flex'}}>
        <Title
          sx={{width: 'auto'}}
          noBorder
          breadcrumbs={[
            <Link
              component="button"
              variant="body1"
              onClick={() => history.push(ROUTES.CONDITIONALLY_APPROVED)}
            >
              Conditionally Approved
            </Link>,
          ]}
        />
        <Box sx={{flexGrow: 1}}>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(158px, min(20%, 220px)))',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <PanelItem>
              <TextField
                value={selectedGroup ?? DEFAULT_VALUE}
                onChange={handleGroupChange}
                label="Filter by Group"
                sx={{width: '100%', ml: 3.1}}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem value="0">All</MenuItem>
                {(groups.data ?? []).sortAndMap(
                  group => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ),
                  group => group.name
                )}
              </TextField>
            </PanelItem>
            <PanelItem dividerPosition="start">
              <TextField
                value={selectedAgent ?? DEFAULT_VALUE}
                onChange={handleAgentChange}
                label="Filter by Agent"
                sx={{width: '100%'}}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem value="0">All</MenuItem>
                {orderByLodash(filteredAgents ?? [], ['firstName', 'lastName']).map(agent => (
                  <MenuItem key={agent.id} value={agent.id}>
                    {agent.firstName} {agent.lastName}
                  </MenuItem>
                ))}
              </TextField>
            </PanelItem>
            {isAdmin && (
              <PanelItem dividerPosition="start">
                <TextField
                  value={lifeStatus}
                  onChange={handleLifeStatusChange}
                  variant="standard"
                  label="Filter by State"
                  sx={{width: '100%'}}
                  InputProps={{
                    classes: {
                      input: classes.selectInput,
                    },
                    disableUnderline: true,
                  }}
                  select
                >
                  {Object.values(ApplicationLifeStatusFilter).sortAndMap(e => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </TextField>
              </PanelItem>
            )}
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(158px, min(20%, 220px)))',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <PanelItem sx={{gridColumn: 'span 2'}}>
              <InputSearch
                fieldName="searchApplications"
                placeholder="Search..."
                onSearchChange={setSearchQuery}
                fullWidth
              />
            </PanelItem>
            <PanelItem dividerPosition="start">
              <Button
                size="small"
                onClick={toggleShowAll}
                startIcon={
                  !showAll ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />
                }
              >
                Assigned to me
              </Button>
            </PanelItem>
            <PanelItem dividerPosition="start">
              <Button
                size="small"
                onClick={toggleOrder}
                endIcon={
                  searchCriteria.order === 'desc' ? (
                    <SortIcon style={{transform: 'rotateX(180deg)'}} />
                  ) : (
                    <SortIcon />
                  )
                }
              >
                {searchCriteria.order === 'desc' ? 'Newest at Top' : 'Oldest at Top'}
              </Button>
            </PanelItem>

            {isAdmin && (
              <ApplicationsExportButton
                filename="conditionally-approved-applications"
                getRows={fetchAll}
                rows={applications.data?.items}
                sx={{ml: 2, alignSelf: 'center', justifySelf: 'center'}}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Divider sx={{margin: '5px 0'}} />

      {isLoadingFirstPage || authProfile.promised || applications.promised ? (
        <Box sx={{position: 'relative', top: '40%', m: '0 auto'}}>
          <CircularProgress color="primary" />
        </Box>
      ) : !applications.data?.items?.length ? (
        <Typography sx={{top: '40%', position: 'relative', textAlign: 'center'}}>
          No Applications
        </Typography>
      ) : (
        <InfiniteEntities
          showTotal
          data={applications.data}
          itemSize={270}
          loadNextPage={loadNextPage}
          onRender={(application: ApplicationResponse) => (
            <Card
              application={application}
              customizeTransferable
              onClick={() => history.push(`boarded/${application.id}`, {referrer: 'Boarded'})}
              profiles={profiles}
              group={groupsMap[application.group.id]}
            />
          )}
        />
      )}
    </Box>
  );
};

export default ConditionallyApproved;
